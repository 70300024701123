import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import BlockLabel from 'common/components/BlockLabel';

class BlockLabelDemo extends Component {
  render() {
    const props = {
      title: 'This is my title',
      htmlFor: 'input-id',
      description: 'This is the description of what is happening'
    };

    return (
      <div>
        <BlockLabel {...props} />
        <div className="styleguide-code-example">
          <pre>
{
`
const props = {
  title: 'This is my title',
  htmlFor: 'input-id',
  description: 'This is the description of what is happening'
};
return (<BlockLabel {...props} />);
`
}
          </pre>
        </div>
      </div>
    );
  }
}

$(() => {
  ReactDOM.render(
    React.createElement(BlockLabelDemo),
    document.getElementById('component-demo')
  );
});
